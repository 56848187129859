import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
};

export default function SwitchModal({
    openModal = false,
    onClose = () => { },
}) {
    return (
        <Modal
            open={openModal}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-descript ion"
        >
            <Box sx={style} className="bg-[#191a1a] rounded-[40px] border-[#2f2f36] border-[1px] w-[360px] h-[350px]">
                <div className="relative flex flex-col">
                    <div className="text-lg text-[#d1d6d6] m-auto font-bold my-4 ">
                        Not Eligible
                    </div>
                    <div className="justify-center items-center border-t-[1px] border-[#2f2f36]">
                        <img
                            className="w-24 h-24 m-auto bg-[#252726] rounded-2xl my-14" src={'./images/failed.png'} />

                    </div>
                    <div
                        className="bg-[#1e1e1e] rounded-full text-base text-[#747a7c] text-center py-2 mt-4 w-[300px] m-auto"
                        onClick={onClose}
                    >
                        Please try another wallet
                    </div>
                </div>
            </Box>
        </Modal>
    );
}
