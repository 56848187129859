import { _TypedDataEncoder, shallowCopy } from "ethers/lib/utils";

export const getPayload = (domain, types, values) => {
    const domainFieldTypes = {
        name: "string",
        chainId: "uint256",
        verifyingContract: "address",
    };

    const domainFieldNames = [
        "name", "chainId", "verifyingContract"
    ];
    const domainValues = {};
    const domainTypes = [];

    domainFieldNames.forEach((name) => {
        const value = domain[name];
        if (value == null) { return; }
        domainValues[name] = value;
        domainTypes.push({ name, type: domainFieldTypes[name] });
    });

    const typesWithDomain = shallowCopy(types);

    typesWithDomain.EIP712Domain = domainTypes;

    // Validate the data structures and types

    return {
        types: typesWithDomain,
        domain: domainValues,
        primaryType: "PermitSingle",
        message: values
    }
}
